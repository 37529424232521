"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
function randChoice(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
}
class CoinflipperCtrl {
    constructor($scope, $timeout, ConfigService, SoundService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.ConfigService = ConfigService;
        this.SoundService = SoundService;
        this.isFlipping = false;
        this.currentDegrees = parseInt(localStorage.getItem(`${this.constructor.name}_currentDegrees`) || randChoice(['0', '180']));
        this.states = JSON.parse(localStorage.getItem(`${this.constructor.name}_states`) || '[]');
        this.heads = [];
        this.tails = [];
        this.coinPerClick = 1;
        this.statistics = false;
        this.stepDelay = 500;
        this.animationDuration = 1700;
        this.$scope.$watch('$ctrl.states.length', (states_length) => {
            localStorage.setItem(`${this.constructor.name}_states`, JSON.stringify(ng.copy(this.states)));
            this.heads = this.states.filter((item) => item.coin == 'head');
            this.headsPercent = this.heads.length * 100 / states_length;
            this.tails = this.states.filter((item) => item.coin == 'tail');
        });
    }
    $onInit() {
        this.coin = document.getElementById('coin');
        if (this.coin) {
            this.coin.style.transform = `rotateX(${this.currentDegrees}deg)`;
        }
    }
    flipItem() {
        // this.isFlipping = true
        this.play();
        const random = Math.floor(Math.random() * 4 + 9);
        // this.currentDegrees += 180 * random;
        this.currentDegrees += randChoice([1800, 1980]);
        console.log('this.currentDegrees', this.currentDegrees);
        if (this.coin) {
            this.coin.style.transform = `rotateX(${this.currentDegrees}deg)`;
            const p = this.$timeout(() => {
                if (this.currentDegrees % 360 === 0) {
                    localStorage.setItem(`${this.constructor.name}_currentDegrees`, '0');
                    this.states.unshift({
                        index: this.states.length,
                        coin: 'head',
                    });
                }
                else {
                    localStorage.setItem(`${this.constructor.name}_currentDegrees`, '180');
                    this.states.unshift({
                        index: this.states.length,
                        coin: 'tail'
                    });
                }
            }, this.animationDuration);
            // p.finally(() => {
            //     console.log(
            //         'finally'
            //     )
            //     this.isFlipping = false;
            // })
            return p;
        }
        else {
            return Promise.resolve();
        }
    }
    flip() {
        var _a;
        (_a = this.stop$) === null || _a === void 0 ? void 0 : _a.next();
        this.stop$ = new rxjs_1.ReplaySubject();
        if (this.statistics) {
            let p;
            this.isFlipping = true;
            (0, rxjs_1.defer)(() => {
                p = this.flipItem();
                return p;
            }).pipe((0, operators_1.mergeMap)(() => {
                return (0, rxjs_1.from)([...Array(this.coinPerClick).keys()]).pipe((0, operators_1.skip)(1), (0, operators_1.map)((i) => {
                    let offset = 0;
                    if (this.states.length)
                        offset = this.states[0].index;
                    return {
                        index: i + offset,
                        coin: randChoice(['head', 'tail'])
                    };
                }));
            }), (0, operators_1.toArray)(), (0, operators_1.tap)((z) => {
                this.states = z.reverse().concat(this.states);
                if (((this.states[0].coin == 'head') && (this.currentDegrees % 360 != 0)) ||
                    ((this.states[0].coin == 'tail') && (this.currentDegrees % 360 === 0))) {
                    this.currentDegrees += 180;
                    localStorage.setItem(`${this.constructor.name}_currentDegrees`, (this.currentDegrees % 360).toString());
                    if (this.coin)
                        this.coin.style.transform = `rotateX(${this.currentDegrees}deg)`;
                }
            }), (0, operators_1.takeUntil)(this.stop$), (0, operators_1.finalize)(() => {
                if (p.hasOwnProperty('$$state')) {
                    //@ts-ignore
                    if (p.$$state.status != 1) {
                        console.log('cancel');
                        this.$timeout.cancel(p);
                    }
                }
                this.isFlipping = false;
            })).subscribe();
        }
        else {
            (0, rxjs_1.timer)(0, this.animationDuration).pipe((0, operators_1.startWith)(-1), (0, operators_1.concatMap)((i) => {
                this.isFlipping = true;
                let p;
                return (0, rxjs_1.defer)(() => {
                    p = this.flipItem();
                    return p;
                }).pipe((0, operators_1.switchMap)(() => {
                    return this.$timeout(() => { }, this.stepDelay);
                }), (0, operators_1.mapTo)(i), 
                //@ts-ignore
                (0, operators_1.takeUntil)(this.stop$), (0, operators_1.finalize)(() => {
                    if (p.hasOwnProperty('$$state')) {
                        //@ts-ignore
                        if (p.$$state.status != 1) {
                            console.log('cancel');
                            this.$timeout.cancel(p);
                        }
                    }
                    console.log('finalize timeout');
                }));
            }), (0, operators_1.tap)((i) => {
                console.log(i);
            }), (0, operators_1.take)(this.coinPerClick), (0, operators_1.takeUntil)(this.stop$), (0, operators_1.finalize)(() => {
                this.isFlipping = false;
            })).subscribe();
        }
    }
    reset() {
        var _a;
        (_a = this.stop$) === null || _a === void 0 ? void 0 : _a.next();
        localStorage.removeItem(`${this.constructor.name}_currentDegrees`);
        // this.currentDegrees = randChoice([0, 180])
        // if (this.coin) {
        //     this.coin.style.transform = `rotateX(${this.currentDegrees}deg)`;
        // }
        this.states = [];
        localStorage.removeItem(`${this.constructor.name}_states`);
    }
    stop() {
        var _a;
        (_a = this.stop$) === null || _a === void 0 ? void 0 : _a.next();
    }
    play() {
        // return
        this.SoundService.stop_all();
        this.$timeout(() => {
            this.SoundService.play('~Coinflip', false, 0.75);
        }, 100);
    }
}
CoinflipperCtrl.$inject = ['$scope', '$timeout', 'ConfigService', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gameCoinflipper', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: CoinflipperCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider',
    (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('coinflipper/');
        SoundServiceProvider.setSound({
            '~Coinflip': require('./sounds/coin.ogg').default,
        });
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
        });
    }]);
